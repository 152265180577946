import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/aaron/Apps/fresh-pets/src/components/mdx-layout/mdx-layout.js";
import SEO from '../components/seo/seo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title='FAQs' description='Get answers to frequently asked questions.' keywords={['Fresh', 'Pets', 'FAQs']} mdxType="SEO" />
    <h1>{`FAQs`}</h1>
    <h2>{`How long will the groom take?`}</h2>
    <p>{`On average a full groom will take 2.5 hours however the length of time is dependable on size, coat type and temperament.`}</p>
    <h2>{`Do you have a mild shampoo for itchy skin?`}</h2>
    <p>{`Yes we do. We can also offer advice on possible causes and solutions when you come in for your appointment.`}</p>
    <h2>{`My dog is knotty, will this cost extra?`}</h2>
    <p>{`A few knots here and there will not increase the price however, severe matting will result in higher charges due to the added wear on our equipment and added time this type of groom will take.`}</p>
    <h2>{`Can I stay with my dog whilst being groomed?`}</h2>
    <p>{`We find that the majority of dogs, even very nervous ones, cope well once grooming has started and can actually become more anxious with their owner present.`}</p>
    <p>{`If you are worried that your dog is going to find the grooming process stressful, you can stay close by and a member of the team will phone you to come back and stay if necessary.`}</p>
    <h2>{`Do you offer a dry clip service?`}</h2>
    <p>{`We do not offer a dry clip service.`}</p>
    <h2>{`Do you do ear plucking?`}</h2>
    <p>{`Only if absolutely necessary and with owners consent. We will not pluck ears if there is any sign of an infection or trauma to the ear.`}</p>
    <h2>{`Do you do anal gland expression?`}</h2>
    <p>{`We do not offer this service. It has been advised by the RCVS (Royal College of Veterinary Surgeons) that it is considered an invasive treatment and should be carried out by a vet. We can check and advise you on whether they require expression.`}</p>
    <h2>{`What type of payment do you accept?`}</h2>
    <p>{`We accept the following payment types:`}</p>
    <ul>
      <li parentName="ul">{`Cash payment`}</li>
      <li parentName="ul">{`Card payment`}</li>
    </ul>
    <p>{`We currently do not accept cheques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      