/**
 *
 * FreshPets MDX Layout
 *
 */

import PropTypes from 'prop-types'
import React from 'react'
import { Container } from 'theme-ui'
import Layout from '../layout/layout'

const MdxLayout = ({ children }) => (
    <React.Fragment>
        <Layout>
            <Container
                sx={{
                    // px: 3,
                    pb: 3
                }}>
                {children}
            </Container>
        </Layout>
    </React.Fragment>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default MdxLayout
